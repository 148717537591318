









import {Component, Vue} from 'vue-property-decorator';
import InternetConnectionFailed from "@/components/general/InternetConnectionFailed.vue";
import {MeQuery, SetUserTimeZone, UpdateUserMutation} from '@/graphql/auth';
import gql from 'graphql-tag';
//import {Board} from "@/typescript/types";
import PusherService from "@/PusherService";
import MobileBlocker from "@/components/auth/MobileBlocker.vue";
import {getAllPlans} from "@/graphql/plan";
import VerifyUser from "@/components/auth/VerifyUser.vue";
import OfferNotificationBar from './components/general/OfferNotificationBar.vue';
// import OnboardingComplete from './components/OnboardingComplete.vue';
import RoomTutorial from './components/auth/demo/RoomTutorial.vue';



@Component({
  components: {MobileBlocker, InternetConnectionFailed, OfferNotificationBar},
  apollo: {
    me: {
      query: MeQuery,
      pollInterval: 30000,
      skip() {
        return !this.$store.getters.isAuthenticated;
      },
      subscribeToMore: [
        {
          document: gql`
                        subscription UserInviteUpdate {
                            userInviteUpdate {
                                id
                                pid
                                workspace {
                                    id
                                    pid
                                    name
                                }
                            }
                        }
                    `,
          updateQuery: (previous, {subscriptionData}) => {
            if(previous != undefined) {
              if(subscriptionData.data.userInviteUpdate != null) {
                previous.me.invites.unshift(subscriptionData.data.userInviteUpdate);
              }
              return {
                me: previous.me,
              };
            }

          },
        },
        {
          document: gql`
                        subscription UserNotificationUpdate {
                            userNotificationUpdate {
                                id
                                message {
                                    source
                                    message
                                    message_detail_id
                                    message_detail_board_pid
                                    message_detail_workspace_pid
                                }
                                read_at
                                created_at
                            }
                        }
                    `,
          updateQuery: (previous, {subscriptionData}) => {
            
            let timer: any;
            let audio = new Audio(require('@/assets/audio/chat-message.mp3'));
            if (Notification.permission !== "denied" && Notification.permission !== 'granted') {
              //@ts-ignore
              this.$events.fire('open-browser-notofication-permission');
            }

            if (!("Notification" in window)) {
              return
            }

            if (Notification.permission === "granted") {
              if (timer) {
                clearTimeout(timer);
              }
              if(subscriptionData.data.userNotificationUpdate != null) {
                timer = setTimeout(() => {
                  new Notification(subscriptionData.data.userNotificationUpdate.message.message);
                  audio.volume = 0.2;
                  audio.play();
                }, 5000);
              }

            }
            if(previous != undefined) {
              if(subscriptionData.data.userNotificationUpdate != null) {
                previous.me.notifications.data.unshift(subscriptionData.data.userNotificationUpdate);

              }
              return {
                me: previous.me,
              };
            }

          },
        },
      ],
      result({data}) {
        this.$store.commit('set_me', data.me);
        this.initialLoad(data)
        return data.me;
      },
      update(result) {
        this.$store.commit('set_me', result.me);
        return result.me;
      }
    },
    plans: {
      query: getAllPlans,
      result({data}) {
        this.$store.commit('set_all_plans', data.plans);
      }
    }
  }
})
export default class App extends Vue {
  userNetworkOffline = false;
  timer: any ;
  currentIpAddress: string | null = null;
  isAutoGen: boolean = false;


  initialLoad(result: any) {
    // If no workspaces exists for user redirect to '/create-workspace'
    if(result.me.workspaces.length <= 0 && !this.isAutoGen && this.$route.name !== 'register' && this.$route.name !== 'registration-steps'){
      this.$router.push({name: 'workspace-create' })
    }

    if(result.me.timezone == null) {
      this.updateUserTimezone();
    }
    let workspace1 = result.me.workspaces[0];

    if (result && !this.$store.state.workspace) {
      this.$store.commit('set_active_board_pid', null);

      if (workspace1) {
        this.$store.commit('set_workspace', (workspace1.my_access === 1) ? workspace1 : null);
        this.$store.commit('set_active_workspace_pid', (workspace1.my_access === 1) ? workspace1 : null);
        this.$store.commit('set_active_workspace_access', workspace1.my_access);
        if(workspace1.my_access === 0) {
          this.$router.push({ name: 'dashboard-layout', params: { workspace_pid: workspace1.pid } });
        }else{
          this.$router.push({ name: 'WorkspaceBlocked'});
        }

      }
    }

    if (this.$store.state.workspace && workspace1.pid === this.$store.state.workspace.pid) {

      let workspace = result.me.workspaces.find((workspacePid: string) => {
        return workspacePid === this.$store.state.active_workspace_pid;
      });

      if (!workspace) {
        return result;
      }
      this.$store.commit('set_active_workspace_access', workspace.my_access);

      /*

      let boardExists = workspace.boards.find((board: Board) => {
        return board.pid === this.$store.state.active_board_pid
      })

      if (!boardExists) {
        let firstBoard = workspace.boards[0];
        let deletedBoard = this.$store.state.board;
        this.$store.commit('set_active_board_pid', null)
        this.$store.commit('set_board', null)
        this.$router.push({ name: 'board-layout', params: { workspace_pid: firstBoard.pid } });
        this.$store.dispatch('delete_board', deletedBoard)
      }
      */
    }else{
      if(workspace1.pid != this.$store.state.workspace.pid) {
        if(this.$store.state.workspace.my_access === 0) {
          this.$router.push({ name: 'dashboard-layout', params: { workspace_pid: this.$store.state.workspace.pid } });
        }else{
          this.$router.push({ name: 'WorkspaceBlocked'});
        }
      }else{
        if (workspace1) {
          this.$store.commit('set_workspace', (workspace1.my_access === 1) ? workspace1 : null);
          this.$store.commit('set_active_workspace_pid', (workspace1.my_access === 1) ? workspace1 : null);
          this.$store.commit('set_active_workspace_access', workspace1.my_access);
          if(workspace1.my_access === 0) {
            this.$router.push({ name: 'dashboard-layout', params: { workspace_pid: workspace1.pid } });
          }else{
            this.$router.push({ name: 'WorkspaceBlocked'});
          }

        }
      }

    }

    return result;
  }

  addLastActive(id: string) {
    this.$apollo
      .mutate({
        mutation: UpdateUserMutation,
        variables: {
          id: id,
          last_active_date: new Date(),
        }
      })
      .then(_ => {
        //
      })
      .catch(error => {
        console.error(error);
      })
  }

  created() {
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('offline', this.networkConnectionFailed);
    window.addEventListener('online', this.networkConnectionSuccessful);
    window.addEventListener('beforeunload', this.handlerBrowserClose);
    // window.addEventListener('focus', this.removeSoundPlay);
    // window.addEventListener('blur', this.setPlaySound);
  }

  networkConnectionFailed() {
    this.userNetworkOffline = true;
    Vue.prototype.$consoleLog('🔴 Offline: Network connection lost');
  }

  networkConnectionSuccessful() {
    Vue.prototype.$consoleLog('✅ Online: Network connection succeeded');
    this.userNetworkOffline = false;
  }

  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('offline', this.networkConnectionFailed);
    window.removeEventListener('online', this.networkConnectionSuccessful);
  }

  // beforeMount() {
  //   if (this.$store.state.currently_onboarding_in_room) {
  //     this.$router.push({ name: "registration-steps" });
  //   }
  // }

  mounted() {

    if (this.$store.state.currently_on_verification_code_step_email) {
      this.$router.push({ name: "register-layout" });
    }
  
    if(this.$store.state.currently_on_verification_code_step && this.$store.state.me && !this.$store.state.currently_onboarding) {
      this.$buefy.modal.open({
        component: VerifyUser,
        props: {
          clickedClaim: true
        },
        canCancel: !this.$store.state.currently_on_verification_code_step,
        width: '500px',
        parent: this,
      });
    }

    this.handleWindowResize();
    if (process.env.NODE_ENV === 'development') {
      Vue.prototype.$consoleLog('Hi! 👋 This is the development environment of Pembio App');
    }
    if(this.$store.state.me) {
      this.addLastActive(this.$store.state.me.id);
    }

    this.$events.listen('auto-gen', (_) => {
      this.isAutoGen = true;
    })


  }

  handlerBrowserClose() {
    if(this.$store.state.active_board_pid) {
      PusherService.unsubscribeBoardSubscription(this.$store.state.active_board_pid);
    }
    PusherService.unsubscribeWorkspaceSubscription(this.$store.state.active_workspace_pid);
    this.addLastActive(this.$store.state.me.id);
  }

  removeSoundPlay() {
    this.$store.commit('set_playSound', false);
  }

  setPlaySound() {
    this.$store.commit('set_playSound', true);
  }

  handleWindowResize() {
    // Todo: Add debounce to this
    /**
     * First we get the viewport height and we multiple it by 1% to get a value for a vh unit
     */
    let vh = window.innerHeight * 0.01;
    /**
     * Then we set the value in the --vh custom property to the root of the document
     */
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  openComplete() {
    this.$buefy.modal.open({
      component: RoomTutorial,
      width: '800px',
      parent: this,
      customClass: 'room_onboarding_modal'
    });
  }

  updateUserTimezone() {

    //@ts-ignore
    this.$ipaddress.getIpAddress().then((res: string)=>{
      this.currentIpAddress = res;
      this.$apollo.mutate({
        mutation: SetUserTimeZone,
        variables:{
          ipAddress: this.currentIpAddress
        },
        refetchQueries: [{ query: MeQuery }],
      });
    });


  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get isFromAd() {
    return this.$store.state.user_from_ad
  }
}
