




























































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import formatDistance from "date-fns/formatDistance";
import ObjectiveDetailed from '@/components/board/objective/ObjectiveDetailed.vue';
import {
  KeyResult as KeyResultObject,
  Objective as ObjectiveObject,
  Objective as ObjectiveType,
  ObjectiveStatus, Reaction, User
} from '@/typescript/types';
import DateTimeInput from '@/components/board/DateTimeInput.vue';
import UserAvatar from "@/components/user/UserAvatar.vue";
import KeyResult from "@/components/board/KeyResult.vue";
import gql from "graphql-tag";
import PusherService from '@/PusherService';
import Template from "@/components/templates/Template.vue";
import {MarkNotificationAsReadMutation, MeQuery} from "@/graphql/auth";
import {CreateReactionMutation, RemoveReactionMutation} from "@/graphql/reaction";
import {CompletedObjectiveQuery} from "@/graphql/workspace";
import {
  DeleteObjectiveMutation,
  ObjectiveDetailedFragment,

  UpdateObjective
} from "@/graphql/objective";
import DuplicateObjective from "@/components/board/objective/DuplicateObjective.vue";
import {addDays, isFuture, isPast} from "date-fns";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import contextMenu from 'vue-context-menu';
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import RoomTutorial from '@/components/auth/demo/RoomTutorial.vue';

@Component({
  components: {
    Template,
    KeyResult,
    UserAvatar,
    contextMenu,
    DateTimeInput,
    ObjectiveDetailed,
    ConfettiCanon
  },
})
export default class Objective extends Vue {
  open: boolean = false;
  defaultBorderColor: string | null = '#272626b3';
  completed: boolean = false;
  completed_at: Date | null = null;
  rejected: boolean = false;
  whoReactedText: string | null = null;
  delayedDelete: boolean = false;


  emojis: Array<{ icon: string, name: string }> = [
    {icon: '👍', name: 'like'},
    {icon: '👎', name: 'dislike'},
    {icon: '👏', name: 'clap'},
    {icon: '🙏', name: 'pray'},
    {icon: '😄', name: 'smile'},
    {icon: '😢', name: 'sad'},
    {icon: '❤️️', name: 'love'}
  ];

  isStatusChangedToCompleted: boolean = false;
  lastClickTime: number = 0;
  allowSwitchStatus: boolean = true;


  @Prop({default: undefined, required: false})
  currentDueDate!: Date;

  @Prop({default: undefined, required: true})
  objective!: ObjectiveType;

  @Prop({default: undefined, required: false})
  index!: Number;

  @Prop({default: undefined, required: false})
  activeIndex!: Number;

  @Prop({required: false, default: false})
  allCompleted!: boolean | null;

  @Prop({required: false, default: null})
  hasUnreadCommentId!: String;

  @Prop({required: false, default: null})
  page!: Number;

  @Prop({required: false, default: null})
  containerName!: String;

  @Prop({required: false, default: false})
  HasParentGoal!: boolean | null;

  @Prop({required: false, default: false})
  shouldOpenShareUrl!: boolean

  @Prop({required: false, default: false})
  hasPassedDueDate!: boolean
  
  @Prop({required: false, default: null})
  nsStartDate!: Date

  @Prop({required: false, default: null})
  nsDueDate!: Date


  /* Needed to to be able to call `this.$parent.close()` below,
     * see https://github.com/buefy/buefy/issues/1184
     */
  public $parent: any;

  mounted() {
    if(status === 'completed') {
      this.completed = true;
    }

  }

  get status(): string {
    let objective = this.objective;

    if (!objective) {
      return 'Not started'
    }

    if (objective.status === ObjectiveStatus.InProgress) {
      return 'In progress'
    }

    if (objective.status === ObjectiveStatus.NotStarted) {
      return 'Not started'
    }

    if (objective.status === ObjectiveStatus.Pending) {
      return 'Pending'
    }

    if (objective.status === ObjectiveStatus.Completed) {
      return 'Completed'
    }

    if (objective.status === ObjectiveStatus.Cancelled) {
      return 'Cancelled'
    }

    return 'Not started'
  }

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get isCompleted() {
    return this.status === 'Completed';
  }

  get isPast() {
    return isPast(new Date(this.objective.due_date));
  }

  get isInGridView() {
    if(this.$store.state.dashboard_view === 'grid' && !this.$store.state.board) {
      return true;
    } else {
      return false;
    }
  }

  get showOwner() {
    return this.$store.state.objective_data_members;
  }

  get showStatus() {
    return this.$store.state.objective_data_status;
  }

  get showStartDate() {
    return this.$store.state.objective_data_start_date;
  }

  get showDueDate() {
    return this.$store.state.objective_data_due_date;
  }

  get expansionActivityId() {
    return this.$store.state.expansion_activity_id;
  }

  get focusId() {
    return this.$store.state.focus_id;
  }

  get newObjectiveId() {
    return this.$store.state.new_objective
  }

  playSound() {
    if(this.$store.state.mute_notification_sound) {
      // this plays when an initiative is marked as completed
      let audio = new Audio(require('@/assets/audio/initiative-check.mp3'));
      audio.volume = 0.02;
      audio.play();
    }
  }


  get northStarColor() {
    //Todo: rewrite this..
    if(this.objective) {
      if(this.objective.northStars && this.objective.northStars!.length > 0 && this.containerName !== 'due') {
        if(this.objective.northStars[0]!.color) {
          return this.objective.northStars[0]!.color
        } else {
          return this.defaultBorderColor;
        }
      } else {
        return this.defaultBorderColor;
      }
    }
    return this.defaultBorderColor;

  }

  get isBoardMember(){
    if(this.$store.state.board){
      let index = this.$store.state.board.members?.findIndex((member: any)=>{ return member.user.id === this.$store.state.me.id });

      if(index != -1){
        return true;
      }
      return false;
    }

    return true;

  }

  get keyResults(): KeyResultObject[] {
    if (!this.objective.key_results) {
      return []
    }

    return this.objective.key_results?.data;
  }

  get totalKeyResults(): number {
    return this.keyResults.length;
  }

  get totalFinishedKeyResults(): number {
    return this.keyResults.filter((keyResult: KeyResultObject) => {
      return keyResult.completed
    }).length;
  }



  get activeMembersText() {
    let memberCount = this.$props.objective.active_users.length;

    let memberText = '';
    if (memberCount > 0) {
      let text = '';
      memberText = this.$props.objective.active_users.map((user: User, i: any) => {

        if (memberCount === i + 1 && memberCount != 1) {
          return text + ' & ' + user.first_name;
        } else if (memberCount - 1 === i + 1) {
          return text + user.first_name;
        } else if (memberCount === 1) {
          return text + user.first_name;
        } else {
          return text + user.first_name + ', ';
        }

      }).join('');
      memberText += (memberCount > 1) ? ' are active on this now.' : ' is active on this now.';
    }
    return memberText;
  }

  get shouldChangeUrlToShare() {
    return false;
  }

  forHumans(d: Date) {
    return formatDistance(new Date(d), new Date());
  }

  openDeleteModal() {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDelete,
      props: {
        deleteItemName: "Are you sure you want to delete this activity?"
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if(value === true) {
            this.deleteObjective();
          }
        }
      },
      width: '500px',
    });
  }

  async handleSetFocus(objective: any) {
    this.$nextTick(() => {
     
      this.$store.commit('set_focus_id', objective.id);
      this.$store.commit('set_in_focus', true);
      
      // this.pushInFocusActivtyToTop();
    });
  }

  handleCheckboxClick() {
    if (this.isBoardMember && this.allowSwitchStatus) {
      this.allowSwitchStatus = false;
      this.switchStatus();

      
      setTimeout(() => {
        this.allowSwitchStatus = true;
      }, 900);
    }
  }

  switchStatus() {
    const delay = 300;
    if(this.objective.status !== ObjectiveStatus.Completed) {
      this.isStatusChangedToCompleted = true;
      if (this.objective && this.objective.northStars && this.objective.northStars.length > 0) {
        this.$events.fire('completed-activity-id', this.objective.northStars[0].id);
      }
      setTimeout(() => {
        this.completed = true;
        this.completed_at = new Date();
        this.playSound();
        this.toggleObjectiveCompleted('completed');
        this.$emit('marked-completed', true);
        this.$gtag.event('pmb_user_activity_marked_completed');
      }, delay);
    } else {
      this.completed_at = null;
      this.toggleObjectiveCompleted('not_started');
      this.isStatusChangedToCompleted = false;
    }
  }

  handleObjectiveClicked () {
    this.objective != undefined ? (this.isBoardMember ? this.objectiveClicked(this.objective.pid) : null) : null 
  }

  openComplete() {
    this.$buefy.modal.open({
      component: RoomTutorial,
      width: '800px',
      parent: this,
      customClass: 'room_onboarding_modal'
    });
  }

  toggleObjectiveCompleted(status: string) {
    
    this.$apollo.mutate({
      mutation: gql`
                mutation updateObjective($id: ID!, $status: ObjectiveStatus, $completed_at: DateTime) {
                    updateObjective(id: $id, input: { status: $status, completed_at: $completed_at }) {
                        ...ObjectiveDetailed
                    }
                }
                ${ObjectiveDetailedFragment}
            `,
      variables: {
        id: this.objective.id,
        status: status,
        completed_at: this.completed_at
      },
      refetchQueries:[
        // {query: ObjectiveQuery, variables:{pid: this.objective.pid}},
        // {query: CompletedObjectiveQuery, variables:{workspace_id: this.workspace.id, board_id: (this.board) ? this.board.id : null}}
      ]

    }).then((result: any) => {
      this.$gtag.event('pmb_activity_marked_completed');
      this.completed = true;
      this.$emit('status-updated',{objId: this.objective.id, status: result.data.updateObjective.status});
      this.$events.fire('objective-updated-from-northstar',result.data.updateObjective);
      this.$events.fire('objective-updated', result.data.updateObjective);


      if(status === 'completed') {
        this.$events.fire('master-dashboard-completed-objective', result.data.updateObjective);
      }
    });
  }

  objectiveClicked(_pid: string) {
    this.$emit('clicked', true);
    this.openObjectiveDetailed();
  }

  readNotification(){
    this.$apollo.mutate({
      mutation: MarkNotificationAsReadMutation,
      variables:{
        id: this.hasUnreadCommentId
      },
      refetchQueries: [{ query: MeQuery }],
    });
  }

  delayedDeleteObjective() {
    let delay = 4000;
    let allowDelete = true;
    this.delayedDelete = true;
    this.$buefy.snackbar.open({
      message: 'Undo',
      //@ts-ignore
      pauseOnHover: true,
      actionText: 'undo',
      duration: delay,
      type: 'is-link',
      onAction: () => {
        allowDelete = false;
        this.delayedDelete = false;
        this.$buefy.toast.open({
          message: 'Cancelled',
          queue: false
        })
        return
      }
    })
    setTimeout(() => {
      if(allowDelete) {
        this.deleteObjective();
      }
    }, delay);
  }

  deleteObjective() {
  
    this.$apollo.mutate({
      mutation: DeleteObjectiveMutation,
      variables: {
        id: this.objective.id,
      },
    }).then((res: any) => {
      this.$events.fire('objective-deleted', res.data.deleteObjective);
    })
  }

  openObjectiveDetailed() {

  

    PusherService.objectiveSubscription(this.objective.pid);
    if(this.hasUnreadCommentId){
      this.readNotification();
    }

    let modal = this.$buefy.modal.open({
      component: ObjectiveDetailed,
      props: {
        objectivePid: this.objective.pid,
        openObjectiveShareUrl: this.shouldOpenShareUrl,
        nsStartDate: this.nsStartDate,
        nsDueDate: this.nsDueDate,
      },
      events: {
        'objective-updated': (objective: ObjectiveObject) => {
          this.$emit('objective-updated', objective)
        }
      },
      // @ts-ignore
      customClass: 'objective-modal',
      parent: this,
    });

    modal.$on("close", () => PusherService.unsubscribeObjectiveSubscription(this.objective.pid));


  }

  get myReactions() {
    return this.objective?.reactions?.filter((reaction: Reaction) => {
      return reaction.reacter.id === this.me.id;
    });
  }

  get myReactedEmojis() {
    let reactions = this.myReactions;
    let emojis = reactions?.map((react: Reaction) => {
      return react.reaction;
    });
    return emojis;
  }

  get isOngoing() {
    if (this.objective.status != 'completed' && this.objective.start_date != null && isPast(new Date(this.objective.start_date)) && (this.objective.due_date == null || isFuture(new Date(this.objective.due_date))) ) {
      return true;
    }
    return false
  }

  isReactedEmojis(emoji: string): boolean {
    //@ts-ignore
    return this.myReactedEmojis?.includes(emoji);
  }

  react(reaction: string, id: string) {
    let randId = Math.random().toString(36).substring(7);
    this.$emit('new-reaction', {
      id: randId,
      created_at: new Date(),
      reaction: reaction,
      reactions : {
        avatar: null,
        id: this.me.id,
        initials: this.me.initials,
        name: this.me.name,
        __typename: "User"
      },
      __typename: "Reaction",
    }, {messageId: this.$props.chatMessageId});

    this.$apollo
      .mutate({
        mutation: CreateReactionMutation,
        variables: {
          reactable_type: 'App\\Models\\Objective',
          reactable_id: id,
          reaction: reaction
        },
        refetchQueries: [{
          query: CompletedObjectiveQuery,
          variables: {
            id: this.$store.state.workspace.id,
            page: this.page
          }
        }],
      })
  }

  isMeReacter(emoji: string): boolean {
    let isMe = this.reactorsWithEmoji(emoji).filter((e: any) => e.id === this.me.id);
    if (isMe.length > 0) {
      return true;
    } else {
      return false
    }
  }

  reactedEmoji(emoji: string) {
    return this.emojis.find((em: any) => {
      return em.name === emoji;
    });
  }

  reactorsWithEmoji(emoji: string): Array<String> {
    let reacters = this.objective?.reactions?.map((reacter: Reaction) => {
      if (reacter.reaction != emoji) {
        return;
      }
      return reacter.reacter;
    });
    //@ts-ignore
    return reacters?.filter((rec: any) => {
      return rec !== undefined;
    });
  }

  getNamesWhoReacted(emoji: string): void {
    let names = this.reactorsWithEmoji(emoji).map((reactername: any) => {
      return reactername.name;
    })
    this.whoReactedText = names.join();
  }

  removeReaction(emoji: string) {
    let myReaction = this.myReactions;
    let data = myReaction?.find((react: Reaction) => {
      return react.reaction === emoji;
    });

    if (!data) {
      return;
    }

    this.$apollo
      .mutate({
        mutation: RemoveReactionMutation,
        variables: {
          id: data.id
        },
        refetchQueries: [{
          query: CompletedObjectiveQuery,
          variables: {
            id: this.$store.state.workspace.id,
            page: this.page
          }
        }],
      })
  }


  getGhostParent() {
    return document.body;
  }

  duplicateObjective() {
    this.$buefy.modal.open({
      component: DuplicateObjective,
      props: {
        objective: this.objective
      },
      width: '500px',
      parent: this,

    })
  }

  setToOngoing() {
    this.$apollo.mutate({
      mutation: UpdateObjective,
      variables: {
        id: this.objective.id,
        start_date: new Date()
      }
    }).then((_) => {
      this.$events.fire('load-all-objectives');
      this.$buefy.toast.open({
        message: 'Activity set to ongoing',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    })
  }

  snooze(days: number, start: boolean, clear: boolean) {
    let newDate = new Date();
    if(this.objective.due_date && !start) {
      newDate = new Date(this.objective.due_date);
    } else if(this.objective.start_date && start) {
      newDate = new Date(this.objective.start_date);
    }

    if(clear) {
      this.$apollo.mutate({
        mutation: UpdateObjective,
        variables: {
          id: this.objective.id,
          date: null
        }
      }).then((res: any) => {
        this.$events.fire('snooze-objective-clear', res.data.updateObjective);
      })
    }
    else if(!start) {
      this.$apollo.mutate({
        mutation: UpdateObjective,
        variables: {
          id: this.objective.id,
          date: addDays(newDate, days)
        }
      })
    } else {
      this.$apollo.mutate({
        mutation: UpdateObjective,
        variables: {
          id: this.objective.id,
          start_date: addDays(newDate, days)
        }
      })
    }
    if(!clear) {
      this.$buefy.toast.open({
        message: 'Snoozed for '+days+' days',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    } else {
      this.$buefy.toast.open({
        message: 'Due date removed',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    }
  }

}
